@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700,800,300);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700,800,300);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700,800,300);
/* line 4, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_box-sizing.scss */
html {
  box-sizing: border-box; }

/* line 9, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_box-sizing.scss */
*, *::after, *::before {
  box-sizing: inherit; }

@font-face {
  font-family: 'slick';
  src: url("../fonts/slick.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#svgFontName") format("svg");
  /* Legacy iOS */ }

/* line 1, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0; }

/* line 8, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
.aqua_color {
  color: #6ecbb8; }

/* line 13, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
.blue_color {
  color: #007580; }

/* line 18, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
.responsive_container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em; }
  /* line 20, /Users/guillermo/Code/tut/bower_components/bourbon/app/assets/stylesheets/addons/_clearfix.scss */
  .responsive_container::after {
    clear: both;
    content: "";
    display: table; }

/* line 25, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
.tut_button {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 0.75em 1.25em;
  font-size: 16px;
  background-color: #6cc04a;
  color: white;
  border: none;
  cursor: pointer; }
  /* line 36, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  .tut_button:hover {
    background-color: white;
    color: #6cc04a;
    border: 1px solid #6cc04a; }

/* line 45, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
#menu_bar_section_placeholder {
  display: block;
  height: 9em; }
  /* line 20, /Users/guillermo/Code/tut/bower_components/bourbon/app/assets/stylesheets/addons/_clearfix.scss */
  #menu_bar_section_placeholder::after {
    clear: both;
    content: "";
    display: table; }

/* line 51, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
#menu_bar_section {
  height: 9em;
  position: fixed;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid lightgray;
  z-index: 100; }
  /* line 60, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #menu_bar_section .responsive_container {
    display: block;
    margin-top: 0;
    padding-top: 0.5em;
    height: 8.5em;
    background-color: white; }
    /* line 20, /Users/guillermo/Code/tut/bower_components/bourbon/app/assets/stylesheets/addons/_clearfix.scss */
    #menu_bar_section .responsive_container::after {
      clear: both;
      content: "";
      display: table; }
    /* line 69, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #menu_bar_section .responsive_container .logo_container {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 14.70196%; }
      /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
      #menu_bar_section .responsive_container .logo_container:last-child {
        margin-right: 0; }
      /* line 73, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
      #menu_bar_section .responsive_container .logo_container img {
        height: 7em;
        display: block;
        margin: 0 auto; }
    /* line 81, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #menu_bar_section .responsive_container .menu_container {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 74.41059%; }
      /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
      #menu_bar_section .responsive_container .menu_container:last-child {
        margin-right: 0; }
      /* line 85, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
      #menu_bar_section .responsive_container .menu_container .upper_menu_container {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 100%;
        height: 4em; }
        /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
        #menu_bar_section .responsive_container .menu_container .upper_menu_container:last-child {
          margin-right: 0; }
        /* line 91, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
        #menu_bar_section .responsive_container .menu_container .upper_menu_container .telephone_container {
          margin-left: 34.11922%;
          float: left;
          display: block;
          margin-right: 2.35765%;
          width: 31.76157%; }
          /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
          #menu_bar_section .responsive_container .menu_container .upper_menu_container .telephone_container:last-child {
            margin-right: 0; }
          /* line 96, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
          #menu_bar_section .responsive_container .menu_container .upper_menu_container .telephone_container img {
            width: 38px;
            float: left; }
          /* line 102, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
          #menu_bar_section .responsive_container .menu_container .upper_menu_container .telephone_container p {
            float: left;
            text-align: right;
            color: #565656;
            font-size: 18px;
            font-weight: bolder;
            padding: 0;
            margin: 0.5em 0 0 0.75em; }
        /* line 114, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
        #menu_bar_section .responsive_container .menu_container .upper_menu_container .schedule_button_container {
          float: left;
          display: block;
          margin-right: 2.35765%;
          width: 31.76157%; }
          /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
          #menu_bar_section .responsive_container .menu_container .upper_menu_container .schedule_button_container:last-child {
            margin-right: 0; }
          /* line 118, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
          #menu_bar_section .responsive_container .menu_container .upper_menu_container .schedule_button_container .tut_button {
            margin-top: 0.5em;
            float: right;
            margin-right: 2em; }
      /* line 127, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
      #menu_bar_section .responsive_container .menu_container .lower_menu_container {
        margin-left: 25.58941%;
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 74.41059%;
        height: 3em; }
        /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
        #menu_bar_section .responsive_container .menu_container .lower_menu_container:last-child {
          margin-right: 0; }
        /* line 134, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
        #menu_bar_section .responsive_container .menu_container .lower_menu_container ul {
          list-style-type: none;
          margin-left: 2.5em; }
          /* line 139, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
          #menu_bar_section .responsive_container .menu_container .lower_menu_container ul li {
            float: left;
            margin-left: 2em; }
            /* line 144, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
            #menu_bar_section .responsive_container .menu_container .lower_menu_container ul li:first-child {
              margin-left: 0em; }
            /* line 149, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
            #menu_bar_section .responsive_container .menu_container .lower_menu_container ul li a {
              text-decoration: none;
              color: #565656;
              font-size: 20px; }
              /* line 155, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
              #menu_bar_section .responsive_container .menu_container .lower_menu_container ul li a:hover {
                color: #6cc04a;
                border-bottom: 3px solid green; }
    /* line 166, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #menu_bar_section .responsive_container .mobile_menu_icon_container {
      display: none; }
      /* line 170, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
      #menu_bar_section .responsive_container .mobile_menu_icon_container img {
        display: none; }

/* line 178, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
#mobile_menu_section {
  background-color: #6cc04a;
  padding: 1em 0;
  display: none; }
  /* line 186, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #mobile_menu_section .responsive_container ul {
    list-style-type: none;
    padding: 0;
    margin: 0; }
    /* line 192, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #mobile_menu_section .responsive_container ul li {
      margin-bottom: 1em; }
      /* line 195, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
      #mobile_menu_section .responsive_container ul li a {
        text-decoration: none;
        color: white;
        font-size: 28px; }
        /* line 201, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
        #mobile_menu_section .responsive_container ul li a:hover {
          text-decoration: underline; }

/* line 211, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
#photo_carousel {
  display: block;
  margin-top: 2.5em;
  height: 470px;
  min-height: 100%;
  max-height: 100%;
  margin-bottom: 0;
  padding: 5px 0; }
  /* line 20, /Users/guillermo/Code/tut/bower_components/bourbon/app/assets/stylesheets/addons/_clearfix.scss */
  #photo_carousel::after {
    clear: both;
    content: "";
    display: table; }
  /* line 222, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #photo_carousel .mobile_carousel_image {
    display: none; }
  /* line 227, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #photo_carousel div {
    -webkit-box-shadow: 4px 4px 3px 0px rgba(100, 100, 100, 0.5);
    -moz-box-shadow: 4px 4px 3px 0px rgba(100, 100, 100, 0.5);
    box-shadow: 4px 4px 3px 0px rgba(100, 100, 100, 0.5);
    width: 1200px;
    margin: 0 auto; }
    /* line 236, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #photo_carousel div img {
      display: block;
      margin: 0 auto;
      border-left: 1px solid #F0F0F0;
      border-top: 1px solid #F0F0F0; }

/* line 247, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
#photo_carousel_overlay {
  display: block;
  height: 420px;
  width: 1200px;
  margin: 0 auto;
  margin-top: -470px;
  position: relative;
  z-index: 50;
  padding: 0 1.5em; }
  /* line 20, /Users/guillermo/Code/tut/bower_components/bourbon/app/assets/stylesheets/addons/_clearfix.scss */
  #photo_carousel_overlay::after {
    clear: both;
    content: "";
    display: table; }
  /* line 260, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #photo_carousel_overlay #banner0_overlay {
    margin-top: 1em; }
    /* line 264, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #photo_carousel_overlay #banner0_overlay h2 {
      font-size: 48px; }
    /* line 269, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #photo_carousel_overlay #banner0_overlay .info_container {
      float: left;
      width: 300px;
      color: white; }
    /* line 276, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #photo_carousel_overlay #banner0_overlay #video_frame_container {
      width: 765px;
      float: right;
      text-align: center; }
  /* line 284, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #photo_carousel_overlay #banner1_overlay {
    display: none; }
    /* line 289, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #photo_carousel_overlay #banner1_overlay h2 {
      font-size: 56px; }
    /* line 294, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #photo_carousel_overlay #banner1_overlay p {
      width: 40%;
      color: white;
      font-size: 22px; }
    /* line 301, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #photo_carousel_overlay #banner1_overlay .action_call_container {
      margin-top: 3.5em;
      width: 50%; }
      /* line 306, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
      #photo_carousel_overlay #banner1_overlay .action_call_container .tut_button {
        margin-right: 2em;
        margin-bottom: 0.5em; }
  /* line 314, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #photo_carousel_overlay #banner2_overlay {
    display: none; }
    /* line 318, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #photo_carousel_overlay #banner2_overlay h2 {
      font-size: 56px;
      width: 50%; }
    /* line 324, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #photo_carousel_overlay #banner2_overlay p {
      width: 50%;
      color: #565656;
      font-size: 22px; }
  /* line 332, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #photo_carousel_overlay #banner3_overlay {
    display: none; }
    /* line 336, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #photo_carousel_overlay #banner3_overlay h2 {
      font-size: 56px;
      width: 50%; }
    /* line 342, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #photo_carousel_overlay #banner3_overlay p {
      width: 40%;
      color: white;
      font-size: 22px; }

/* line 351, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
#specials_section {
  background-color: #6cc04a;
  height: 8em;
  margin-top: 4em;
  overflow: hidden; }
  /* line 357, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #specials_section .responsive_container {
    margin-top: 0; }
    /* line 360, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #specials_section .responsive_container .special_banner_container {
      display: block;
      height: 8em;
      color: white; }
      /* line 20, /Users/guillermo/Code/tut/bower_components/bourbon/app/assets/stylesheets/addons/_clearfix.scss */
      #specials_section .responsive_container .special_banner_container::after {
        clear: both;
        content: "";
        display: table; }

/* line 372, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
#aboutus_section {
  margin-top: 2em; }
  /* line 379, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #aboutus_section .responsive_container h2.section_title {
    border-top: 1px solid lightgray;
    color: #565656;
    text-align: center;
    font-size: 32px;
    padding: 2em 0; }
  /* line 388, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #aboutus_section .responsive_container #business_container {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    background-image: url("/images/business_photo_background.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 50em;
    color: white;
    padding-top: 10em;
    margin-bottom: 2em; }
    /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
    #aboutus_section .responsive_container #business_container:last-child {
      margin-right: 0; }
    /* line 401, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #aboutus_section .responsive_container #business_container h2 {
      margin-left: 2em;
      width: 50%;
      font-size: 32px; }
    /* line 408, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #aboutus_section .responsive_container #business_container p {
      margin-left: 4em;
      width: 60%; }
    /* line 414, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #aboutus_section .responsive_container #business_container p.main_description {
      margin-top: 3em; }
    /* line 419, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #aboutus_section .responsive_container #business_container ul {
      list-style-type: none;
      margin: 0;
      margin-left: 4em;
      padding: 0; }
      /* line 425, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
      #aboutus_section .responsive_container #business_container ul li {
        width: 80%; }
  /* line 435, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #aboutus_section #team_container h2 {
    border-top: 1px solid lightgray;
    color: #565656;
    text-align: center;
    font-size: 32px;
    padding: 2em 0; }
  /* line 444, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #aboutus_section #team_container .team_member {
    background-color: #007580; }
    /* line 448, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #aboutus_section #team_container .team_member .responsive_container {
      padding: 0.25em; }
      /* line 452, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
      #aboutus_section #team_container .team_member .responsive_container .member_profile_picture {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 23.23176%;
        height: 15em;
        margin-top: 2em; }
        /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
        #aboutus_section #team_container .team_member .responsive_container .member_profile_picture:last-child {
          margin-right: 0; }
        /* line 459, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
        #aboutus_section #team_container .team_member .responsive_container .member_profile_picture .member_name {
          color: white;
          text-align: center; }
        /* line 465, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
        #aboutus_section #team_container .team_member .responsive_container .member_profile_picture img {
          display: block;
          height: 12em;
          margin: 0 auto; }
      /* line 473, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
      #aboutus_section #team_container .team_member .responsive_container .member_description {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 74.41059%;
        text-align: right;
        padding: 2em;
        color: white; }
        /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
        #aboutus_section #team_container .team_member .responsive_container .member_description:last-child {
          margin-right: 0; }
  /* line 484, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #aboutus_section #team_container .team_member.inverted {
    background-color: white; }
    /* line 493, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #aboutus_section #team_container .team_member.inverted .responsive_container .member_profile_picture .member_name {
      color: #565656; }
    /* line 498, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #aboutus_section #team_container .team_member.inverted .responsive_container .member_description {
      color: #565656;
      text-align: left; }

/* line 513, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
#services_section .responsive_container #services_container {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 100%;
  margin-top: 2em; }
  /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
  #services_section .responsive_container #services_container:last-child {
    margin-right: 0; }
  /* line 518, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #services_section .responsive_container #services_container h2 {
    border-top: 1px solid lightgray;
    text-align: center;
    font-size: 32px;
    color: #565656;
    padding: 2em 0 0.5em 0; }
  /* line 527, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #services_section .responsive_container #services_container h3 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    color: #565656;
    text-align: center;
    border-bottom: 3px solid lightgray;
    padding: 1em 0;
    font-size: 38px;
    color: #565656; }
    /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
    #services_section .responsive_container #services_container h3:last-child {
      margin-right: 0; }
  /* line 539, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #services_section .responsive_container #services_container p {
    width: 65%;
    margin: 0 auto;
    font-size: 18px;
    text-align: center;
    margin-bottom: 3em; }
  /* line 548, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #services_section .responsive_container #services_container .tut_service_button {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    padding: 0.75em 1.25em;
    font-size: 16px;
    background-color: #007580;
    color: white;
    border: none;
    margin: 1em auto 0 auto;
    display: block;
    cursor: pointer; }
    /* line 561, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #services_section .responsive_container #services_container .tut_service_button:hover {
      background-color: white;
      border: 1px solid #007580;
      color: #007580; }
  /* line 569, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #services_section .responsive_container #services_container .service_row {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    margin-top: 3em; }
    /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
    #services_section .responsive_container #services_container .service_row:last-child {
      margin-right: 0; }
    /* line 575, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #services_section .responsive_container #services_container .service_row .service {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 31.76157%;
      padding-bottom: 3em; }
      /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
      #services_section .responsive_container #services_container .service_row .service:last-child {
        margin-right: 0; }
      /* line 78, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_omega.scss */
      #services_section .responsive_container #services_container .service_row .service:nth-child(3n) {
        margin-right: 0; }
      /* line 83, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_omega.scss */
      #services_section .responsive_container #services_container .service_row .service:nth-child(3n+1) {
        clear: left; }
      /* line 582, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
      #services_section .responsive_container #services_container .service_row .service img {
        display: block;
        margin: 0 auto;
        width: 40%; }
      /* line 589, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
      #services_section .responsive_container #services_container .service_row .service h4 {
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #565656; }
      /* line 597, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
      #services_section .responsive_container #services_container .service_row .service p {
        width: 90%;
        font-size: 14px;
        margin: 0 auto;
        padding: 0;
        color: gray;
        height: 7em; }
      /* line 607, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
      #services_section .responsive_container #services_container .service_row .service .more_info_block {
        height: 15em;
        background-color: #6ecbb8;
        display: none;
        margin-top: 1.5em; }
        /* line 614, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
        #services_section .responsive_container #services_container .service_row .service .more_info_block p {
          font-size: 16px;
          padding: 1em 0.5em;
          color: white; }
  /* line 624, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #services_section .responsive_container #services_container .more_info_row {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    height: 15em;
    background-color: #6ecbb8;
    display: none; }
    /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
    #services_section .responsive_container #services_container .more_info_row:last-child {
      margin-right: 0; }
    /* line 632, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #services_section .responsive_container #services_container .more_info_row h2 {
      border: none;
      color: white;
      padding: 0; }
    /* line 639, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #services_section .responsive_container #services_container .more_info_row p {
      color: white; }

/* line 652, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
#facilities_section .responsive_container #gallery_container {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 100%;
  margin-bottom: 4em; }
  /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
  #facilities_section .responsive_container #gallery_container:last-child {
    margin-right: 0; }
  /* line 657, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #facilities_section .responsive_container #gallery_container h2 {
    border-top: 1px solid lightgray;
    color: #565656;
    text-align: center;
    font-size: 32px;
    padding: 2em 0; }
  /* line 666, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #facilities_section .responsive_container #gallery_container #facilities_photos_container {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%; }
    /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
    #facilities_section .responsive_container #gallery_container #facilities_photos_container:last-child {
      margin-right: 0; }
    /* line 670, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #facilities_section .responsive_container #gallery_container #facilities_photos_container a {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 31.76157%;
      margin-bottom: 2em; }
      /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
      #facilities_section .responsive_container #gallery_container #facilities_photos_container a:last-child {
        margin-right: 0; }
      /* line 78, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_omega.scss */
      #facilities_section .responsive_container #gallery_container #facilities_photos_container a:nth-child(3n) {
        margin-right: 0; }
      /* line 83, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_omega.scss */
      #facilities_section .responsive_container #gallery_container #facilities_photos_container a:nth-child(3n+1) {
        clear: left; }
      /* line 677, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
      #facilities_section .responsive_container #gallery_container #facilities_photos_container a img {
        display: block;
        margin: 0 auto;
        max-width: 100%; }

/* line 693, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
#contact_section .responsive_container #contact_container {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 100%;
  height: 40em;
  margin-bottom: 4em; }
  /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
  #contact_section .responsive_container #contact_container:last-child {
    margin-right: 0; }
  /* line 700, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #contact_section .responsive_container #contact_container h2 {
    border-top: 1px solid lightgray;
    color: #565656;
    text-align: center;
    font-size: 32px;
    padding: 2em 0; }
  /* line 709, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #contact_section .responsive_container #contact_container .form_container {
    margin-left: 8.5298%;
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%; }
    /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
    #contact_section .responsive_container #contact_container .form_container:last-child {
      margin-right: 0; }
    /* line 714, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #contact_section .responsive_container #contact_container .form_container .tut_textbox {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 48.82117%;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      font-size: 20px;
      height: 2em;
      margin-bottom: 1.5em;
      padding: 0.5em;
      border: 2px solid #6cc04a;
      color: #565656; }
      /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
      #contact_section .responsive_container #contact_container .form_container .tut_textbox:last-child {
        margin-right: 0; }
      /* line 78, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_omega.scss */
      #contact_section .responsive_container #contact_container .form_container .tut_textbox:nth-child(2n) {
        margin-right: 0; }
      /* line 83, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_omega.scss */
      #contact_section .responsive_container #contact_container .form_container .tut_textbox:nth-child(2n+1) {
        clear: left; }
    /* line 728, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #contact_section .responsive_container #contact_container .form_container .tut_textbox.large {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%; }
      /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
      #contact_section .responsive_container #contact_container .form_container .tut_textbox.large:last-child {
        margin-right: 0; }
    /* line 733, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #contact_section .responsive_container #contact_container .form_container textarea.tut_textbox {
      height: 8em; }
    /* line 738, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #contact_section .responsive_container #contact_container .form_container .tut_button {
      display: block;
      width: 8em;
      padding: 0.75em;
      font-size: 20px;
      margin: 0 auto;
      background-color: #6cc04a; }
      /* line 747, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
      #contact_section .responsive_container #contact_container .form_container .tut_button:hover {
        background-color: white;
        color: #6cc04a;
        border: 1px solid #6cc04a; }

/* line 759, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
#footer_section {
  background-color: #565656; }
  /* line 765, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
  #footer_section .responsive_container #footer_container {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    height: 40em;
    margin-bottom: 1em;
    color: white; }
    /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
    #footer_section .responsive_container #footer_container:last-child {
      margin-right: 0; }
    /* line 773, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #footer_section .responsive_container #footer_container h4 {
      font-weight: normal;
      color: white; }
    /* line 779, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #footer_section .responsive_container #footer_container .contact_container {
      margin-left: 4.21521%;
      float: left;
      display: block;
      margin-right: 1.16509%;
      width: 19.91097%;
      height: 15em; }
      /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
      #footer_section .responsive_container #footer_container .contact_container:last-child {
        margin-right: 0; }
    /* line 787, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #footer_section .responsive_container #footer_container .hours_container {
      float: left;
      display: block;
      margin-right: 1.16509%;
      width: 19.91097%;
      height: 15em; }
      /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
      #footer_section .responsive_container #footer_container .hours_container:last-child {
        margin-right: 0; }
    /* line 794, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #footer_section .responsive_container #footer_container .social_networks_container {
      float: left;
      display: block;
      margin-right: 1.16509%;
      width: 19.91097%;
      height: 15em; }
      /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
      #footer_section .responsive_container #footer_container .social_networks_container:last-child {
        margin-right: 0; }
      /* line 800, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
      #footer_section .responsive_container #footer_container .social_networks_container a {
        font-size: 18px;
        color: lightgray;
        float: left;
        margin-left: 1em; }
        /* line 807, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
        #footer_section .responsive_container #footer_container .social_networks_container a img {
          width: 3.5em; }
      /* line 813, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
      #footer_section .responsive_container #footer_container .social_networks_container a.first {
        margin-left: 0em; }
    /* line 819, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #footer_section .responsive_container #footer_container .logo_container {
      float: left;
      display: block;
      margin-right: 1.16509%;
      width: 28.34139%;
      height: 15em; }
      /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
      #footer_section .responsive_container #footer_container .logo_container:last-child {
        margin-right: 0; }
      /* line 825, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
      #footer_section .responsive_container #footer_container .logo_container img {
        display: block;
        margin: 2em auto 0 auto;
        width: 60%; }
    /* line 833, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #footer_section .responsive_container #footer_container .map_container {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 65.88078%;
      margin-top: 2em;
      height: 22em; }
      /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
      #footer_section .responsive_container #footer_container .map_container:last-child {
        margin-right: 0; }
    /* line 841, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
    #footer_section .responsive_container #footer_container .disclaimer_container {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 31.76157%;
      color: white; }
      /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
      #footer_section .responsive_container #footer_container .disclaimer_container:last-child {
        margin-right: 0; }
      /* line 846, /Users/guillermo/Code/tut/app/styles/_desktop.scss */
      #footer_section .responsive_container #footer_container .disclaimer_container p {
        text-align: center;
        margin-top: 1em;
        font-size: 16px; }

/* line 4, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_box-sizing.scss */
html {
  box-sizing: border-box; }

/* line 9, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_box-sizing.scss */
*, *::after, *::before {
  box-sizing: inherit; }

@font-face {
  font-family: 'slick';
  src: url("../fonts/slick.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#svgFontName") format("svg");
  /* Legacy iOS */ }

@media only screen and (min-width: 47.5em) and (max-width: 63.5em) {
  /* line 7, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
  .responsive_container {
    width: 768px;
    overflow: hidden; }
  /* line 16, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
  #menu_bar_section .responsive_container {
    display: block;
    padding: 0.5em; }
    /* line 20, /Users/guillermo/Code/tut/bower_components/bourbon/app/assets/stylesheets/addons/_clearfix.scss */
    #menu_bar_section .responsive_container::after {
      clear: both;
      content: "";
      display: table; }
    /* line 21, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
    #menu_bar_section .responsive_container .logo_container {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 14.70196%;
      padding: 1em; }
      /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
      #menu_bar_section .responsive_container .logo_container:last-child {
        margin-right: 0; }
      /* line 27, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
      #menu_bar_section .responsive_container .logo_container img {
        height: 5.5em;
        display: block;
        margin: 0 auto; }
    /* line 35, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
    #menu_bar_section .responsive_container .menu_container {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 65.88078%; }
      /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
      #menu_bar_section .responsive_container .menu_container:last-child {
        margin-right: 0; }
      /* line 39, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
      #menu_bar_section .responsive_container .menu_container .upper_menu_container {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 100%; }
        /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
        #menu_bar_section .responsive_container .menu_container .upper_menu_container:last-child {
          margin-right: 0; }
        /* line 43, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
        #menu_bar_section .responsive_container .menu_container .upper_menu_container .telephone_container {
          margin-left: 8.5298%;
          float: left;
          display: block;
          margin-right: 2.35765%;
          width: 74.41059%; }
          /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
          #menu_bar_section .responsive_container .menu_container .upper_menu_container .telephone_container:last-child {
            margin-right: 0; }
        /* line 49, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
        #menu_bar_section .responsive_container .menu_container .upper_menu_container .schedule_button_container {
          float: left;
          display: block;
          margin-right: 2.35765%;
          width: 14.70196%; }
          /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
          #menu_bar_section .responsive_container .menu_container .upper_menu_container .schedule_button_container:last-child {
            margin-right: 0; }
      /* line 55, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
      #menu_bar_section .responsive_container .menu_container .lower_menu_container {
        display: none; }
    /* line 61, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
    #menu_bar_section .responsive_container .mobile_menu_icon_container {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 14.70196%;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      padding: 1em;
      cursor: pointer;
      border: 2px solid transparent; }
      /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
      #menu_bar_section .responsive_container .mobile_menu_icon_container:last-child {
        margin-right: 0; }
      /* line 70, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
      #menu_bar_section .responsive_container .mobile_menu_icon_container img {
        margin: 0 auto;
        display: block;
        height: 3.5em; }
      /* line 78, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
      #menu_bar_section .responsive_container .mobile_menu_icon_container:hover {
        border: 2px solid #6cc04a; }
  /* line 86, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
  #photo_carousel {
    overflow: show; }
    /* line 88, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
    #photo_carousel div {
      width: 760px; }
  /* line 96, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
  #photo_carousel_overlay {
    width: 760px; }
    /* line 102, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
    #photo_carousel_overlay #banner0_overlay #video_frame_container {
      width: 350px; }
      /* line 106, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
      #photo_carousel_overlay #banner0_overlay #video_frame_container iframe {
        width: 350px;
        height: 400px; }
    /* line 116, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
    #photo_carousel_overlay #banner1_overlay h2 {
      font-size: 44px; }
    /* line 121, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
    #photo_carousel_overlay #banner1_overlay p {
      font-size: 18px; }
    /* line 127, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
    #photo_carousel_overlay #banner2_overlay {
      display: none; }
      /* line 131, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
      #photo_carousel_overlay #banner2_overlay h2 {
        font-size: 44px; }
      /* line 136, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
      #photo_carousel_overlay #banner2_overlay p {
        font-size: 18px; }
    /* line 142, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
    #photo_carousel_overlay #banner3_overlay {
      display: none; }
      /* line 146, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
      #photo_carousel_overlay #banner3_overlay h2 {
        font-size: 44px; }
      /* line 151, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
      #photo_carousel_overlay #banner3_overlay p {
        font-size: 18px; }
  /* line 159, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
  #specials_section {
    overflow: hidden; }
    /* line 163, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
    #specials_section .responsive_container .special_banner_container {
      display: block;
      height: 8em;
      color: white; }
      /* line 20, /Users/guillermo/Code/tut/bower_components/bourbon/app/assets/stylesheets/addons/_clearfix.scss */
      #specials_section .responsive_container .special_banner_container::after {
        clear: both;
        content: "";
        display: table; }
      /* line 170, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
      #specials_section .responsive_container .special_banner_container img {
        width: 760px; }
  /* line 186, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
  #services_section .responsive_container #services_container .service_row {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    margin: 0;
    margin-top: 3em; }
    /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
    #services_section .responsive_container #services_container .service_row:last-child {
      margin-right: 0; }
    /* line 193, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
    #services_section .responsive_container #services_container .service_row .service {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 48.82117%;
      padding-bottom: 3em; }
      /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
      #services_section .responsive_container #services_container .service_row .service:last-child {
        margin-right: 0; }
      /* line 78, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_omega.scss */
      #services_section .responsive_container #services_container .service_row .service:nth-child(2n) {
        margin-right: 0; }
      /* line 83, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_omega.scss */
      #services_section .responsive_container #services_container .service_row .service:nth-child(2n+1) {
        clear: left; }
      /* line 200, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
      #services_section .responsive_container #services_container .service_row .service p {
        height: 11em; }
      /* line 205, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
      #services_section .responsive_container #services_container .service_row .service .more_info_block {
        height: 20em;
        background-color: #6ecbb8;
        display: none;
        margin-top: 1.5em; }
        /* line 212, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
        #services_section .responsive_container #services_container .service_row .service .more_info_block p {
          font-size: 15px;
          padding: 1em 0.5em;
          color: white; }
      /* line 220, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
      #services_section .responsive_container #services_container .service_row .service:nth-child(3n+1) {
        clear: none; }
  /* line 235, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
  #aboutus_section .responsive_container #business_container {
    padding-top: 8em; }
    /* line 239, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
    #aboutus_section .responsive_container #business_container h2 {
      width: 90%;
      font-size: 32px; }
    /* line 245, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
    #aboutus_section .responsive_container #business_container p {
      width: 90%; }
    /* line 250, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
    #aboutus_section .responsive_container #business_container ul {
      width: 90%; }
      /* line 254, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
      #aboutus_section .responsive_container #business_container ul li {
        width: 90%; }
  /* line 272, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
  #facilities_section .responsive_container #gallery_container #facilities_photos_container a {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
    margin-bottom: 2em; }
    /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
    #facilities_section .responsive_container #gallery_container #facilities_photos_container a:last-child {
      margin-right: 0; }
    /* line 78, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_omega.scss */
    #facilities_section .responsive_container #gallery_container #facilities_photos_container a:nth-child(3n) {
      margin-right: 0; }
    /* line 83, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_omega.scss */
    #facilities_section .responsive_container #gallery_container #facilities_photos_container a:nth-child(3n+1) {
      clear: left; }
    /* line 279, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
    #facilities_section .responsive_container #gallery_container #facilities_photos_container a img {
      display: block;
      margin: 0 auto;
      max-width: 90%; }
  /* line 292, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
  #footer_section {
    overflow: hidden; }
    /* line 297, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
    #footer_section .responsive_container #footer_container {
      font-size: 12px;
      height: 55em; }
      /* line 302, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
      #footer_section .responsive_container #footer_container .contact_container {
        margin-left: 4.21521%;
        float: left;
        display: block;
        margin-right: 1.16509%;
        width: 19.91097%;
        height: 15em; }
        /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
        #footer_section .responsive_container #footer_container .contact_container:last-child {
          margin-right: 0; }
      /* line 310, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
      #footer_section .responsive_container #footer_container .hours_container {
        float: left;
        display: block;
        margin-right: 1.16509%;
        width: 19.91097%;
        height: 15em; }
        /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
        #footer_section .responsive_container #footer_container .hours_container:last-child {
          margin-right: 0; }
      /* line 317, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
      #footer_section .responsive_container #footer_container .social_networks_container {
        float: left;
        display: block;
        margin-right: 1.16509%;
        width: 19.91097%;
        height: 15em; }
        /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
        #footer_section .responsive_container #footer_container .social_networks_container:last-child {
          margin-right: 0; }
        /* line 323, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
        #footer_section .responsive_container #footer_container .social_networks_container a {
          font-size: 18px;
          color: lightgray;
          float: left;
          margin-left: 1em; }
          /* line 330, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
          #footer_section .responsive_container #footer_container .social_networks_container a img {
            width: 3.5em; }
        /* line 336, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
        #footer_section .responsive_container #footer_container .social_networks_container a.first {
          margin-left: 0em; }
      /* line 342, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
      #footer_section .responsive_container #footer_container .logo_container {
        float: left;
        display: block;
        margin-right: 1.16509%;
        width: 28.34139%;
        height: 15em; }
        /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
        #footer_section .responsive_container #footer_container .logo_container:last-child {
          margin-right: 0; }
        /* line 348, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
        #footer_section .responsive_container #footer_container .logo_container img {
          display: block;
          margin: 2em auto 0 auto;
          width: 60%; }
      /* line 356, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
      #footer_section .responsive_container #footer_container .map_container {
        overflow: hidden;
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 100%;
        margin-top: 2em;
        height: 22em; }
        /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
        #footer_section .responsive_container #footer_container .map_container:last-child {
          margin-right: 0; }
      /* line 365, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
      #footer_section .responsive_container #footer_container .disclaimer_container {
        margin-left: 8.5298%;
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 82.94039%; }
        /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
        #footer_section .responsive_container #footer_container .disclaimer_container:last-child {
          margin-right: 0; }
        /* line 369, /Users/guillermo/Code/tut/app/styles/_tablet.scss */
        #footer_section .responsive_container #footer_container .disclaimer_container p {
          margin-top: 2em;
          font-size: 14px; } }

/* line 4, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_box-sizing.scss */
html {
  box-sizing: border-box; }

/* line 9, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_box-sizing.scss */
*, *::after, *::before {
  box-sizing: inherit; }

@font-face {
  font-family: 'slick';
  src: url("../fonts/slick.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#svgFontName") format("svg");
  /* Legacy iOS */ }

@media only screen and (max-width: 47.5em) {
  /* line 7, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
  .responsive_container {
    width: 360px;
    overflow: hidden; }
  /* line 13, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
  #menu_bar_section {
    height: 6em;
    padding-top: 0.5em; }
    /* line 18, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
    #menu_bar_section .responsive_container {
      display: block;
      height: auto; }
      /* line 20, /Users/guillermo/Code/tut/bower_components/bourbon/app/assets/stylesheets/addons/_clearfix.scss */
      #menu_bar_section .responsive_container::after {
        clear: both;
        content: "";
        display: table; }
      /* line 23, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #menu_bar_section .responsive_container .logo_container {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 65.88078%; }
        /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
        #menu_bar_section .responsive_container .logo_container:last-child {
          margin-right: 0; }
        /* line 27, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
        #menu_bar_section .responsive_container .logo_container img {
          height: 4em; }
      /* line 33, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #menu_bar_section .responsive_container .menu_container {
        display: none; }
      /* line 38, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #menu_bar_section .responsive_container .mobile_menu_icon_container {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 31.76157%;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        padding: 0.5em;
        cursor: pointer;
        border: 2px solid transparent; }
        /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
        #menu_bar_section .responsive_container .mobile_menu_icon_container:last-child {
          margin-right: 0; }
        /* line 47, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
        #menu_bar_section .responsive_container .mobile_menu_icon_container img {
          margin: 0 auto;
          display: block;
          height: 2.5em; }
        /* line 54, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
        #menu_bar_section .responsive_container .mobile_menu_icon_container:hover {
          border: 2px solid #6cc04a; }
  /* line 62, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
  #menu_bar_section_placeholder {
    height: 6em; }
  /* line 67, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
  #mobile_menu_section {
    background-color: #6cc04a;
    padding: 1em 0;
    display: none; }
    /* line 75, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
    #mobile_menu_section .responsive_container ul {
      list-style-type: none;
      padding: 0;
      margin: 0; }
      /* line 81, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #mobile_menu_section .responsive_container ul li {
        margin-bottom: 1em; }
        /* line 84, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
        #mobile_menu_section .responsive_container ul li a {
          text-decoration: none;
          color: white;
          font-size: 22px; }
          /* line 90, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
          #mobile_menu_section .responsive_container ul li a:hover {
            text-decoration: underline; }
  /* line 100, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
  #photo_carousel {
    overflow: show; }
    /* line 102, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
    #photo_carousel .desktop_carousel_image {
      display: none; }
    /* line 107, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
    #photo_carousel .mobile_carousel_image {
      display: block; }
    /* line 112, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
    #photo_carousel div {
      width: 360px; }
  /* line 120, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
  #photo_carousel_overlay {
    width: 360px; }
    /* line 126, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
    #photo_carousel_overlay #banner0_overlay .info_container {
      display: none; }
    /* line 130, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
    #photo_carousel_overlay #banner0_overlay #video_frame_container {
      width: 310px; }
      /* line 134, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #photo_carousel_overlay #banner0_overlay #video_frame_container iframe {
        width: 310px;
        height: 400px; }
    /* line 144, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
    #photo_carousel_overlay #banner1_overlay h2 {
      font-size: 30px; }
    /* line 149, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
    #photo_carousel_overlay #banner1_overlay p {
      font-size: 16px; }
    /* line 155, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
    #photo_carousel_overlay #banner2_overlay {
      display: none; }
      /* line 159, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #photo_carousel_overlay #banner2_overlay h2 {
        font-size: 30px; }
      /* line 164, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #photo_carousel_overlay #banner2_overlay p {
        font-size: 16px; }
    /* line 170, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
    #photo_carousel_overlay #banner3_overlay {
      display: none; }
      /* line 174, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #photo_carousel_overlay #banner3_overlay h2 {
        font-size: 30px; }
      /* line 179, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #photo_carousel_overlay #banner3_overlay p {
        font-size: 16px; }
      /* line 184, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #photo_carousel_overlay #banner3_overlay .action_call_container {
        margin-top: 3.5em;
        width: 50%; }
        /* line 189, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
        #photo_carousel_overlay #banner3_overlay .action_call_container .tut_button {
          margin-right: 2em;
          margin-bottom: 0.5em; }
  /* line 198, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
  #specials_section {
    display: none;
    overflow: hidden; }
    /* line 205, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
    #specials_section .responsive_container .special_banner_container {
      display: block;
      height: 4em;
      color: white; }
      /* line 20, /Users/guillermo/Code/tut/bower_components/bourbon/app/assets/stylesheets/addons/_clearfix.scss */
      #specials_section .responsive_container .special_banner_container::after {
        clear: both;
        content: "";
        display: table; }
      /* line 212, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #specials_section .responsive_container .special_banner_container img {
        width: 360px; }
  /* line 228, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
  #services_section .responsive_container #services_container .service_row {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    margin: 0;
    margin-top: 3em; }
    /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
    #services_section .responsive_container #services_container .service_row:last-child {
      margin-right: 0; }
    /* line 235, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
    #services_section .responsive_container #services_container .service_row .service {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
      padding-bottom: 3em; }
      /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
      #services_section .responsive_container #services_container .service_row .service:last-child {
        margin-right: 0; }
      /* line 78, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_omega.scss */
      #services_section .responsive_container #services_container .service_row .service:nth-child(1n) {
        margin-right: 0; }
      /* line 83, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_omega.scss */
      #services_section .responsive_container #services_container .service_row .service:nth-child(1n+1) {
        clear: left; }
      /* line 242, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #services_section .responsive_container #services_container .service_row .service p {
        height: 11em; }
      /* line 247, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #services_section .responsive_container #services_container .service_row .service .more_info_block {
        height: 20em;
        background-color: #6ecbb8;
        display: none;
        margin-top: 1.5em; }
        /* line 254, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
        #services_section .responsive_container #services_container .service_row .service .more_info_block p {
          font-size: 15px;
          padding: 1em 0.5em;
          color: white; }
      /* line 262, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #services_section .responsive_container #services_container .service_row .service:nth-child(3n+1) {
        clear: none; }
  /* line 276, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
  #aboutus_section .responsive_container #business_container {
    background-image: none;
    background-color: #6cc04a;
    height: 65em;
    padding-top: 2em; }
    /* line 284, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
    #aboutus_section .responsive_container #business_container h2 {
      margin: 0 1em;
      margin-bottom: 1em;
      width: 90%;
      font-size: 32px; }
    /* line 292, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
    #aboutus_section .responsive_container #business_container p {
      margin: 0 1em;
      margin-bottom: 1em;
      width: 90%; }
    /* line 299, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
    #aboutus_section .responsive_container #business_container ul {
      margin: 0 1em; }
      /* line 302, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #aboutus_section .responsive_container #business_container ul li {
        width: 90%; }
  /* line 310, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
  #aboutus_section #team_container {
    width: 370px;
    margin: 0 auto; }
    /* line 315, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
    #aboutus_section #team_container .team_member {
      background-color: #007580; }
      /* line 319, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #aboutus_section #team_container .team_member .responsive_container {
        padding: 0.25em; }
        /* line 323, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
        #aboutus_section #team_container .team_member .responsive_container .member_profile_picture {
          float: left;
          display: block;
          margin-right: 2.35765%;
          width: 100%;
          height: 15em; }
          /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
          #aboutus_section #team_container .team_member .responsive_container .member_profile_picture:last-child {
            margin-right: 0; }
        /* line 329, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
        #aboutus_section #team_container .team_member .responsive_container .member_description {
          float: left;
          display: block;
          margin-right: 2.35765%;
          width: 100%;
          text-align: center; }
          /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
          #aboutus_section #team_container .team_member .responsive_container .member_description:last-child {
            margin-right: 0; }
    /* line 337, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
    #aboutus_section #team_container .team_member.inverted {
      background-color: white; }
      /* line 346, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #aboutus_section #team_container .team_member.inverted .responsive_container .member_profile_picture .member_name {
        color: #565656; }
      /* line 351, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #aboutus_section #team_container .team_member.inverted .responsive_container .member_description {
        color: #565656;
        text-align: center; }
  /* line 369, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
  #facilities_section .responsive_container #gallery_container #facilities_photos_container a {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    margin-bottom: 2em; }
    /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
    #facilities_section .responsive_container #gallery_container #facilities_photos_container a:last-child {
      margin-right: 0; }
    /* line 78, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_omega.scss */
    #facilities_section .responsive_container #gallery_container #facilities_photos_container a:nth-child(1n) {
      margin-right: 0; }
    /* line 83, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_omega.scss */
    #facilities_section .responsive_container #gallery_container #facilities_photos_container a:nth-child(1n+1) {
      clear: left; }
    /* line 376, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
    #facilities_section .responsive_container #gallery_container #facilities_photos_container a img {
      display: block;
      margin: 0 auto;
      max-width: 90%; }
  /* line 392, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
  #contact_section .responsive_container #contact_container {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    height: 52em; }
    /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
    #contact_section .responsive_container #contact_container:last-child {
      margin-right: 0; }
    /* line 398, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
    #contact_section .responsive_container #contact_container .form_container {
      margin-left: 8.5298%;
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 82.94039%; }
      /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
      #contact_section .responsive_container #contact_container .form_container:last-child {
        margin-right: 0; }
      /* line 403, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #contact_section .responsive_container #contact_container .form_container .tut_textbox {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 100%;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px; }
        /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
        #contact_section .responsive_container #contact_container .form_container .tut_textbox:last-child {
          margin-right: 0; }
        /* line 78, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_omega.scss */
        #contact_section .responsive_container #contact_container .form_container .tut_textbox:nth-child(1n) {
          margin-right: 0; }
        /* line 83, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_omega.scss */
        #contact_section .responsive_container #contact_container .form_container .tut_textbox:nth-child(1n+1) {
          clear: left; }
  /* line 414, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
  #footer_section {
    width: 370px;
    margin: 0 auto;
    padding: 0.25em; }
    /* line 422, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
    #footer_section .responsive_container #footer_container {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
      height: 62em; }
      /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
      #footer_section .responsive_container #footer_container:last-child {
        margin-right: 0; }
      /* line 428, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #footer_section .responsive_container #footer_container .contact_container {
        margin-left: 4.21521%;
        float: left;
        display: block;
        margin-right: 1.16509%;
        width: 40.98703%;
        font-size: 14px; }
        /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
        #footer_section .responsive_container #footer_container .contact_container:last-child {
          margin-right: 0; }
      /* line 435, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #footer_section .responsive_container #footer_container .hours_container {
        margin-left: 8.43042%;
        float: left;
        display: block;
        margin-right: 1.16509%;
        width: 40.98703%;
        font-size: 14px; }
        /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
        #footer_section .responsive_container #footer_container .hours_container:last-child {
          margin-right: 0; }
      /* line 442, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #footer_section .responsive_container #footer_container .social_networks_container {
        float: left;
        display: block;
        margin-right: 1.16509%;
        width: 45.20224%;
        height: 10em; }
        /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
        #footer_section .responsive_container #footer_container .social_networks_container:last-child {
          margin-right: 0; }
      /* line 449, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #footer_section .responsive_container #footer_container .logo_container {
        float: left;
        display: block;
        margin-right: 1.16509%;
        width: 45.20224%;
        height: 10em; }
        /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
        #footer_section .responsive_container #footer_container .logo_container:last-child {
          margin-right: 0; }
      /* line 456, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #footer_section .responsive_container #footer_container .map_container {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 100%; }
        /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
        #footer_section .responsive_container #footer_container .map_container:last-child {
          margin-right: 0; }
      /* line 461, /Users/guillermo/Code/tut/app/styles/_mobile.scss */
      #footer_section .responsive_container #footer_container .disclaimer_container {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 100%; }
        /* line 89, /Users/guillermo/Code/tut/bower_components/neat/app/assets/stylesheets/grid/_span-columns.scss */
        #footer_section .responsive_container #footer_container .disclaimer_container:last-child {
          margin-right: 0; } }
